/* src/InvoicePanel.css */
.invoice-panel {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  
  .invoice-control {
    margin-bottom: 20px;
  }
  
  .konva-container {
    width: 100%;
    height: calc(100% - 40px); /* Adjust based on control panel height */
    box-sizing: border-box;
  }
  