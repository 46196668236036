.quote {
    width: 210mm; /* A4 width */
    height: 297mm; /* A4 height */
    margin: auto;
    padding: 10mm;
    background-color: #f8f7f8;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    border: 1px solid #d2d2d2;
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo .company-logo {
    max-width: 250px;
    height: auto;
}

.quote-title {
    color: #00aaff;
    margin: 0;
    font-size: 24px; /* Adjusted font size for better readability */
}

.quote-details {
    text-align: right;
}

.quote-details .quote-header {
    color: #00aaff;
    margin: 8px 0;
    font-size: 20px; /* Adjusted font size for better readability */
    font-weight: bold;
}

.quote-details p {
    margin: 5px 0;
}

.quote-details .quote-amount {
    font-size: 16px;
    font-weight: bold;
}

.bill-to {
    margin: 10mm 0;
}

.bill-to p {
    margin: 5px 0;
}

.quote-table {
    width: 90%;
    border-collapse: collapse;
    margin-bottom: 10mm;
}

.quote-table th,
.quote-table td {
    border: 0px solid #00aaff;
    padding: 5mm;
    text-align: left;
    font-size: 14px; /* Adjusted font size for better readability */
}

.quote-table th.table-header {
    background-color: #00aaff;
    color: #fff;
}

.item-subdescription {
    font-size: 12px;
    color: #666;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.footer div {
    width: 30%;
}

.footer .thank-you .thank-you-text {
    color: #00aaff;
    margin: 0;
    font-size: 20px; /* Adjusted font size for better readability */
}

.payment-method p,
.contact p {
    margin: 5px 0;
    font-size: 14px; /* Adjusted font size for better readability */
}

.payment-method strong,
.contact strong {
    display: block;
    margin-bottom: 10px;
    font-size: 16px; /* Adjusted font size for better readability */
}
