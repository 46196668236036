body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  background-color: rgb(171, 227, 255); 
  font-family: 'Figtree', sans-serif;
}

.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.background-container {
  display: flex;
  width: 360px; /* 60px for icons + 300px for panel */
  background-color: rgb(122, 211, 255, 0.3); /* Semi-transparent background */
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px); 
  /* border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0px;
  box-sizing: border-box;
}

.icon-column {
  width: 60px;
  display: flex;
  background-color: rgb(255, 255, 255); 
  padding: 0px;
  padding-top: 0px;
  flex-direction: column;
  align-items: center;
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);  */
}

.panel-column {
  width: 340px;
  background-color: rgba(88, 187, 252, 0); /* Transparent to show background image */
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px); 
  overflow-y: auto; 
}

.tabs-container {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgb(128, 128, 128); /* Adjust as needed */
  z-index: 1000; /* Ensure it stays above other content */
}

.overlay-canvas-container {
  z-index: 1000; /* High z-index ensures it's above the Konva stage */
  position: absolute; /* Position it absolutely within the .tabs-container */
  top: 0; /* Adjust as needed */
  left: 0; /* Adjust as needed */
}

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.content-container {
  display: flex;
  flex: 1; /* Ensure it expands to fill the available space */
  flex-direction: column; /* If you want directions and map stacked vertically */
  margin-top: 60px; /* Adjust this value based on the height of your tabs */
  padding: 10px;
  width: 100%; /* Ensure it takes up the full width of the page */
  min-width: 500px; /* Ensure it doesn’t shrink below a certain width */
}


.main-column {
  flex: 1;
  background-color: rgba(209, 242, 255, 0); /* Semi-transparent background */
  padding: 0px;
  align-items: flex-start; /* Align contents to the left */
  /* backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);  */
  border-radius: 0px;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden;
}

.main-column::-webkit-scrollbar {
  width: 25px;
}

.main-column::-webkit-scrollbar-track {
  width: 20px;
  background: rgba(239, 20, 255, 0.5); /* Same as the background */
}

.main-column::-webkit-scrollbar-thumb {
  background-color: #0056b3; /* Dark blue color */
  border-radius: 10px;
  border: 1px solid rgba(209, 242, 255, 0.922); /* Same as the background */
}

.icon {
  font-size: 32px;
  margin: 20px 0;
  cursor: pointer;
}

.icon:hover {
  color: #007bff;
}
