/* Mapping.css */
.postcode-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: #99dafb74;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 0px;
  }
  
  .postcode-item {
    display: flex;
    align-items: left;
    background-color: #25a6fd2f;
    border-radius: 8px;
    margin: 5px 0;
    padding: 10px;
    width: 35%;
  }
  
  .location-icon {
    color: white;
    margin-right: 10px;
  }
  
  .postcode-text {
    color: white;
    font-size: 16px;
  }
  
  .directions-container {
    display: flex;
    flex-direction: column;
  }
  
  .direction-step {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 4px;
  }
  
  .even-step {
    background-color: #00aaff;
  }
  
  .odd-step {
    background-color: #0099ff;
  }
  
  .step-icon {
    color: white;
    margin-right: 10px;
  }
  
  .step-details {
    display: flex;
    flex-direction: column;
  }
  
  .step-index {
    color: white;
    font-weight: bold;
  }
  
  .step-instruction {
    color: white;
  }
  