.report {
    width: 210mm; /* A4 width */
    height: 297mm; /* A4 height */
    margin: auto;
    padding: 10mm;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.hidden {
    display: none !important;
}

#report-container {
    background: white;
    padding: 20px;
    margin: 0;
    width: 210mm; /* A4 width */
    min-height: 297mm; /* A4 height */
}

@media print {
    .hidden {
        display: none !important;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TabPanel {
    display: block !important; /* Ensures all TabPanels are in the DOM */
  }
  
  .TabPanel.hidden {
    display: none !important; /* Add a consistent way to hide inactive tabs in the UI */
  }

.logo .company-logo {
    max-width: 300px;
    height: auto;
}

.report-title {
    color: #00aaff;
    margin: 0;
    font-size: 24px; /* Adjusted font size for better readability */
}

.report-details {
    text-align: right;
}

.report-details .invoice-header {
    color: #00aaff;
    margin: 0;
    font-size: 18px; /* Adjusted font size for better readability */
}

.report-details p {
    margin: 5px 0;
}

.report-details .report-amount {
    font-size: 14px;
    font-weight: bold;
}

.bill-to {
    margin: 10mm 0;
}

.bill-to p {
    margin: 5px 0;
}

.report-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10mm;
}

.report-table th,
.report-table td {
    border: 1px solid #00aaff;
    padding: 5mm;
    text-align: left;
    font-size: 14px; /* Adjusted font size for better readability */
}

.report-table th.table-header {
    background-color: #00aaff;
    color: #fff;
}

.item-subdescription {
    font-size: 12px;
    color: #666;
}

.footer {
    display: flex;
    justify-content: space-between;
}

.footer div {
    width: 30%;
}

.footer .thank-you .thank-you-text {
    color: #00aaff;
    margin: 0;
    font-size: 20px; /* Adjusted font size for better readability */
}


